import { UserContextType } from "./useUser";

declare global {
  interface Window {
    pendo: {
      initialize: (options: {
        visitor: {
          [key: string]: string | number | boolean;
          id: string;
        };
      }) => void;
    };
  }
}

export function usePendo() {
  const init = (user: UserContextType) => {
    const pendoUserData = {
      visitor: {
        id: user.doterraId,
        full_name: `${user.firstName} ${user.lastName}`,
        country: user.market,
        country_iso: user.marketIso,
        max_rank: user.maxRank,
        language: user.language,
        customer_type: user.type,
        email: user.email,
      },
    };

    if (window.pendo) {
      window.pendo.initialize(pendoUserData);
    }
  };

  return {
    init,
  };
}
