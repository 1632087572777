import {
  CommissionSummary,
  CommissionSummaryResponse,
} from "../Services/SalesforceApi";

export const currentCommissionDate = () => {
  const dtTime = new Date(
    new Date().toLocaleString("en-US", {
      timeZone: "America/Denver",
    })
  );

  if (
    dtTime.getDate() <= 1 &&
    dtTime.getHours() <= 1 &&
    dtTime.getMinutes() < 5
  ) {
    dtTime.setHours(-1);
  }

  return dtTime;
};

export const scheduledLrpFromCommissionDate = (
  scheduledLrp: number,
  scheduledDate: Date | string
) => {
  const date = new Date(scheduledDate);
  const commissionDate = currentCommissionDate();

  if (date < commissionDate) {
    return 0;
  }

  return scheduledLrp;
};

export const transformCommissionSummaries = (
  data: CommissionSummaryResponse
) => {
  return data.map(transformCommissionSummary);
};

export const transformCommissionSummary = (data: CommissionSummary) => {
  return {
    ...data,
    scheduledLrpPv: scheduledLrpFromCommissionDate(
      data.scheduledLrpPv,
      data.nextLRPRunDate
    ),
  };
};
