import {
  Footer as DsFooter,
  Link,
  Stack,
  Text,
} from "@doterra-design-system/design-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "../Container";

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <DsFooter>
      <Container>
        <Stack spacing={6} align="center">
          <DsFooter.Links>
            <Link
              href={t("footer.navigation.termsOfServiceUrl")}
              variant="secondary"
            >
              {t("footer.navigation.termsOfService")}
            </Link>
            <Link
              href={t("footer.navigation.cookiePolicyUrl")}
              variant="secondary"
            >
              {t("footer.navigation.cookiePolicy")}
            </Link>
            <Link href={t("footer.navigation.contactUrl")} variant="secondary">
              {t("footer.navigation.contact")}
            </Link>
            <Link
              href={t("footer.navigation.privacyPolicyUrl")}
              variant="secondary"
            >
              {t("footer.navigation.privacyPolicy")}
            </Link>
          </DsFooter.Links>
          <Text size="textXs" align="center">
            {t("footer.copyright", { "0": currentYear })}
          </Text>
        </Stack>
      </Container>
    </DsFooter>
  );
};
