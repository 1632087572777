export function shortDate(date: Date | string | undefined | null) {
  if (!date) {
    return "";
  }

  let newDate;

  if (typeof date === "string") {
    newDate = new Date(date);
  } else {
    newDate = date;
  }

  return newDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
}
