import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { env } from "../Utilities";

export interface AuthContextType {
  token: string;
  callback: () => Promise<boolean>;
  loginRedirect: () => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

const userManager = new UserManager({
  authority: env("REACT_APP_SALESFORCE_OAUTH_AUTHORITY"),
  client_id: env("REACT_APP_SALESFORCE_OAUTH_CLIENT_ID"),
  redirect_uri: env("REACT_APP_SALESFORCE_OAUTH_REDIRECT_URI"),
  scope: "api",
  client_authentication: "client_secret_post",
  metadata: {
    issuer: env("REACT_APP_SALESFORCE_OAUTH_ISSUER"),
    authorization_endpoint: env(
      "REACT_APP_SALESFORCE_OAUTH_AUTHORIZATION_ENDPOINT"
    ),
    token_endpoint: env("REACT_APP_SALESFORCE_OAUTH_TOKEN_ENDPOINT"),
    userinfo_endpoint: env("REACT_APP_SALESFORCE_OAUTH_USERINFO_ENDPOINT"),
    revocation_endpoint: env("REACT_APP_SALESFORCE_OAUTH_REVOCATION_ENDPOINT"),
    end_session_endpoint: env(
      "REACT_APP_SALESFORCE_OAUTH_END_SESSION_ENDPOINT"
    ),
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [token, setToken] = useState("");
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    restoreSession();
  }, []);

  const restoreSession = () => {
    void userManager.getUser().then((user) => {
      if (user) {
        setToken(user.access_token);
      }

      setHasInitialized(true);
    });
  };

  const callback = async (): Promise<boolean> => {
    try {
      const user = await userManager.signinCallback();

      if (user) {
        setToken(user.access_token);
      } else {
        throw new Error("No user");
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const loginRedirect = async () => {
    await userManager.signinRedirect();
  };

  const logout = async () => {
    await userManager.signoutSilent();
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        callback,
        loginRedirect,
        logout,
      }}
    >
      {hasInitialized && children}
    </AuthContext.Provider>
  );
};
