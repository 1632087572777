import { formatCurrency } from "../Utilities/currency";
import { useUser } from "./useUser";

type CommissionSchedule = {
  countryIso: string;
  localeIso: string;
  currencyType: string;
  bonusLevels: CommissionBonusLevels;
};

type CommissionBonusLevels = [number, number, number];

const COMMISSION_SCHEDULES: CommissionSchedule[] = [
  {
    countryIso: "usa",
    localeIso: "en-US",
    currencyType: "USD",
    bonusLevels: [50, 250, 1500],
  },
  {
    countryIso: "kor",
    localeIso: "ko-kr",
    currencyType: "KRW",
    bonusLevels: [50000, 250000, 15000000],
  },
  {
    countryIso: "ita",
    localeIso: "it-IT",
    currencyType: "EUR",
    bonusLevels: [50, 250, 1500],
  },
  {
    countryIso: "chn",
    localeIso: "zh-CN",
    currencyType: "CNY",
    bonusLevels: [333, 1665, 9990],
  },
  {
    countryIso: "bra",
    localeIso: "pt-br",
    currencyType: "BRL",
    bonusLevels: [250, 1250, 7500],
  },
];

export function useCommissionSchedule() {
  const user = useUser();

  let commissionSchedule = COMMISSION_SCHEDULES.find(
    (schedule) => schedule.countryIso === user.marketIso
  );

  if (!commissionSchedule) {
    [commissionSchedule] = COMMISSION_SCHEDULES;
  }

  if (!commissionSchedule) {
    throw new Error("No commission schedule found");
  }

  const getBonusAchievedByPo3Rank = (rank: number) => {
    const bonusLevels = commissionSchedule?.bonusLevels;
    let _rank = rank;

    if (!bonusLevels) {
      throw new Error("No bonus levels found");
    }

    if (_rank <= 0) {
      return 0;
    }

    if (_rank >= bonusLevels[0]) {
      return _rank;
    }

    _rank = _rank - 1;

    const len = bonusLevels.length;

    if (_rank === 0) {
      return bonusLevels[_rank];
    }

    if (_rank) {
      return (
        Math.floor(_rank / len) * bonusLevels[len - 1] +
        bonusLevels[_rank % len]
      );
    }

    return 0;
  };

  const getBonusAchievedByPo3RankFormatted = (rank: number) => {
    const value = getBonusAchievedByPo3Rank(rank);

    return formatCurrency(
      value,
      user.language,
      commissionSchedule?.currencyType,
      false
    );
  };

  return {
    getBonusAchievedByPo3Rank,
    getBonusAchievedByPo3RankFormatted,
    ...commissionSchedule,
  };
}
