import { Button, Stack, Text } from "@doterra-design-system/design-system";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const FullScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <FullScreen>
      <Stack align="center">
        <Text element="h1" size="displayLg">
          404 Error
        </Text>
        <Text element="p">
          Sorry, the page you are looking for does not exist.
        </Text>
        <div>
          <Button
            label="Go home"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </Stack>
    </FullScreen>
  );
};
