import { MonthNumber } from "../types";

export const queryKeys = {
  currentUser: ["currentUser"] as const,
  accountsSearchResults: (searchValue: string) =>
    ["accountSearchResults", searchValue] as const,
  slotsByTimePeriod: (month: MonthNumber, year: number) =>
    ["slots", month, year] as const,
  accountDetails: (doterraIds: string[]) =>
    ["accountDetails", doterraIds] as const,
  commissionSummaries: (
    month: MonthNumber,
    year: number,
    doterraIds: string[]
  ) => ["commissionSummaries", month, year, doterraIds] as const,
  downline: (month: MonthNumber, year: number, doterraId: string) =>
    ["downline", month, year, doterraId] as const,
  commissionSummaryHistory: (
    month: MonthNumber,
    year: number,
    doterraId: string
  ) => ["commissionSummaryHistory", month, year, doterraId] as const,
};
