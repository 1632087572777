import React, { ReactNode, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SalesforceApi } from "../Services/SalesforceApi";
import { useAuth } from "./useAuth";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SalesforceApiContextType extends SalesforceApi {}

const SalesforceApiContext = createContext<SalesforceApiContextType | null>(
  null
);

export const useSalesforceApi = (): SalesforceApiContextType => {
  const context = useContext(SalesforceApiContext);

  if (!context) {
    throw new Error(
      "useSalesforceApi must be used within an SalesforceApiProvider"
    );
  }

  return context;
};

interface SalesforceApiProviderProps {
  children: ReactNode;
}

export const SalesforceApiProvider = ({
  children,
}: SalesforceApiProviderProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const api = new SalesforceApi(auth.token, () => {
    navigate("/login");
  });

  return (
    <SalesforceApiContext.Provider value={api}>
      {children}
    </SalesforceApiContext.Provider>
  );
};
