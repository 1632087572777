import {
  DownlineMember,
  PVTVDetailsModal,
} from "@doterra-design-system/leader-tools-components/Po3";
import { Rank } from "@doterra-design-system/leader-tools-components/Shared";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useCommissionSchedule } from "../../Hooks/useCommissionSchedule";
import { useDownline } from "../../Hooks/useDownline";
import { useSalesforceApi } from "../../Hooks/useSalesforceApi";
import { MonthNumber } from "../../types";
import { queryKeys } from "../../Utilities";
import {
  scheduledLrpFromCommissionDate,
  transformCommissionSummaries,
} from "../../Utilities/comission";

interface TeamMemberDetailsModalProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  doterraId: string;
}

const NOW = new Date();
const THIS_MONTH = new Date(NOW.getFullYear(), NOW.getMonth(), 1);

export const TeamMemberDetailsModal: React.FC<TeamMemberDetailsModalProps> = ({
  state,
  doterraId,
}) => {
  const api = useSalesforceApi();
  const { getBonusAchievedByPo3RankFormatted } = useCommissionSchedule();
  const month = (THIS_MONTH.getMonth() + 1) as MonthNumber;
  const year = THIS_MONTH.getFullYear();

  const { accounts: downline, isLoading: downlineIsLoading } = useDownline(
    month,
    year,
    doterraId
  );

  const { data: commissionSummary, isLoading: commissionSummaryIsLoading } =
    useQuery({
      queryKey: queryKeys.commissionSummaryHistory(month, year, doterraId),
      queryFn: () => api.commissionSummaryHistory(month, year, [doterraId], 7),
      enabled: Boolean(doterraId),
      select: transformCommissionSummaries,
    });

  const { data: accountsDetails, isLoading: accountsDetailsIsLoading } =
    useQuery({
      queryKey: queryKeys.accountDetails([doterraId]),
      queryFn: () => api.accountDetails([doterraId]),
      enabled: Boolean(doterraId),
    });

  const isLoading =
    commissionSummaryIsLoading || accountsDetailsIsLoading || downlineIsLoading;

  const accountDetails = accountsDetails?.[0];
  const commissionSummaryOrdered = commissionSummary?.sort(
    (a, b) =>
      new Date(b.periodYear, b.periodMonth - 1).getTime() -
      new Date(a.periodYear, a.periodMonth - 1).getTime()
  );
  const latestCommissionSummary = commissionSummaryOrdered?.[0];

  const pvLrpMonthlyData =
    commissionSummaryOrdered?.map((summary) => ({
      date: new Date(summary.periodYear, summary.periodMonth - 1),
      lrpPv: summary.lrpPv,
      bonus: getBonusAchievedByPo3RankFormatted(summary.po3Rank ?? 0),
    })) ?? [];

  const downlineMembers: DownlineMember[] =
    downline?.map((account) => ({
      id: account.doTERRAId,
      name: account.firstName + " " + account.lastName,
      maxRankAchieved: Rank.getLabel(account.currentMaxRank),
      currentLrpPv: account.commissionSummary.lrpPv,
      scheduledLrp: scheduledLrpFromCommissionDate(
        account.commissionSummary.scheduledLrpPv,
        account.commissionSummary.nextLRPRunDate
      ),
    })) ?? [];

  return (
    <PVTVDetailsModal
      state={state}
      isLoading={isLoading}
      firstName={accountDetails?.firstName ?? ""}
      lastName={accountDetails?.lastName ?? ""}
      image={accountDetails?.imageUrl ?? ""}
      doterraId={doterraId}
      bonusLevelValue={getBonusAchievedByPo3RankFormatted(
        latestCommissionSummary?.po3Rank ?? 0
      )}
      teamMembersEnrolled={
        latestCommissionSummary?.noEnrolledLastTwoMonths ?? 0
      }
      pvLrpPoints={latestCommissionSummary?.lrpPv ?? 0}
      pvLrpScheduled={latestCommissionSummary?.scheduledLrpPv ?? 0}
      pvLrpTarget={100}
      pvLrpMonthlyData={pvLrpMonthlyData}
      tvPoints={latestCommissionSummary?.tv ?? 0}
      tvScheduled={0}
      tvTarget={100}
      downlineMembers={downlineMembers}
    />
  );
};
