import { FLOATING_ELEMENT_Z_INDEX } from "@doterra-design-system/design-system";
import React from "react";
import styled, { css } from "styled-components";
import { Footer, Header } from "..";

interface LayoutProps {
  children: React.ReactNode;
  headerIsOnTopOfOverlay?: boolean;
  isSaving?: boolean;
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Body = styled.div`
  flex: 1;
`;

type StyledHeaderProps = {
  isOnTopOfOverlay: boolean;
};

const StyledHeader = styled(Header)<StyledHeaderProps>(
  ({ isOnTopOfOverlay }) => css`
    z-index: ${isOnTopOfOverlay ? FLOATING_ELEMENT_Z_INDEX + 1 : null};
    transition: z-index 0s;
    transition-delay: ${isOnTopOfOverlay ? "0s" : ".5s"};
  `
);

export const Layout: React.FC<LayoutProps> = ({
  children,
  headerIsOnTopOfOverlay = false,
  isSaving = false,
}) => {
  return (
    <Main>
      <StyledHeader
        isOnTopOfOverlay={headerIsOnTopOfOverlay}
        isSaving={isSaving}
      />
      <Body>{children}</Body>
      <Footer />
    </Main>
  );
};
