export type EnvironmentVariables = {
  NODE_ENV: "development" | "production" | "test";
  PUBLIC_URL: string;
  REACT_APP_SALESFORCE_API_BASE_URL: string;
  REACT_APP_SALESFORCE_OAUTH_AUTHORITY: string;
  REACT_APP_SALESFORCE_OAUTH_CLIENT_ID: string;
  REACT_APP_SALESFORCE_OAUTH_REDIRECT_URI: string;
  REACT_APP_SALESFORCE_OAUTH_ISSUER: string;
  REACT_APP_SALESFORCE_OAUTH_AUTHORIZATION_ENDPOINT: string;
  REACT_APP_SALESFORCE_OAUTH_TOKEN_ENDPOINT: string;
  REACT_APP_SALESFORCE_OAUTH_USERINFO_ENDPOINT: string;
  REACT_APP_SALESFORCE_OAUTH_REVOCATION_ENDPOINT: string;
  REACT_APP_SALESFORCE_OAUTH_END_SESSION_ENDPOINT: string;
};
export const env = <K extends keyof EnvironmentVariables>(
  key: K
): EnvironmentVariables[K] => {
  const value = process.env[key];

  if (!value) {
    throw new Error(`Missing environment variable: ${key}`);
  }

  return value as unknown as EnvironmentVariables[K];
};
