import { LoadingIndicator } from "@doterra-design-system/design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../Hooks/useAuth";
import { usePendo } from "../../Hooks/usePendo";
import { SalesforceApiProvider } from "../../Hooks/useSalesforceApi";
import { UserContextType, UserProvider } from "../../Hooks/useUser";

const FullScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const AuthenticatedLayout = () => {
  const { t } = useTranslation();
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const isLoading = isLoadingUser;
  const { token } = useAuth();
  const pendo = usePendo();

  if (!token) {
    return <Navigate to="/login" />;
  }

  const onUserLoadingComplete = (user: UserContextType) => {
    setIsLoadingUser(false);
    pendo.init(user);
  };

  return (
    <SalesforceApiProvider>
      <UserProvider onLoadingComplete={onUserLoadingComplete}>
        {isLoading ? (
          <FullScreen>
            <LoadingIndicator label={t("loading")} />
          </FullScreen>
        ) : (
          <Outlet />
        )}
      </UserProvider>
    </SalesforceApiProvider>
  );
};
