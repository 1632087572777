import * as i18n from "i18next";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { env } from "./Utilities";

void i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .init<HttpBackendOptions>({
    debug: env("NODE_ENV") === "development",
    ns: ["application", "po3Components"],
    defaultNS: "application",
    fallbackLng: "en_US",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // TODO: Change this to be the remote URL from Crownpeak
      // loadPath:
      //   "https://doterra-power-3.s3-us-west-2.amazonaws.com/live/{{lng}}/homepage.json",
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
