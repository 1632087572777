import { init as initFullStory } from "@fullstory/browser";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./i18n";
import { env } from "./Utilities";

initFullStory({
  orgId: "11MSMR",
  namespace: "FS",
  devMode: env("NODE_ENV") === "development",
});

createRoot(document.getElementById("root") as HTMLElement).render(<App />);
