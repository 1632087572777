export function formatCurrency(
  amount: number,
  locale = "en-US",
  currency = "USD",
  includeDecimals = true
) {
  // Replace "en_US" with "en-US" to match the locale format
  const formattedLocale = locale.replace("_", "-");

  return new Intl.NumberFormat(formattedLocale, {
    style: "currency",
    currency,
    minimumFractionDigits: includeDecimals ? 2 : 0,
    maximumFractionDigits: includeDecimals ? 2 : 0,
  }).format(amount);
}
