import { env, fetchJson, ResponseError } from "../../Utilities";
import { MonthNumber } from "../../types";
import { SalesforceApiError } from "./SalesforceApiError";
import {
  AccountDetailsResponse,
  CommissionSummaryResponse,
  DownlineResponse,
  Slot,
  SlotsResponse,
  UserResponse,
} from "./types";

export class SalesforceApi {
  private readonly baseUrl: string;
  private readonly accessToken: string;
  private readonly onUnauthorized: () => void;

  public constructor(accessToken: string, onUnauthorized: () => void) {
    this.accessToken = accessToken;
    this.onUnauthorized = onUnauthorized;
    this.baseUrl = env("REACT_APP_SALESFORCE_API_BASE_URL");
  }

  async user() {
    return this.fetchWithAuth<UserResponse>("/services/apexrest/PO3/user");
  }

  async accountDetails(doterraIds: string[]) {
    return this.fetchWithAuth<AccountDetailsResponse>(
      `/services/apexrest/PO3/account?doterraids=[${doterraIds.join(",")}]`
    );
  }

  async accountSearch(query: string) {
    return this.fetchWithAuth<AccountDetailsResponse>(
      `/services/apexrest/PO3/account?searchTerm=${query}`
    );
  }

  async downline(doterraId: string, month: MonthNumber, year: number) {
    return this.fetchWithAuth<DownlineResponse>(
      `/services/apexrest/PO3/account?downlineDoterraId=${doterraId}&offset=0&month=${month}&year=${year}`
    );
  }

  async commissionSummary(
    month: MonthNumber,
    year: number,
    doterraIds: string[]
  ) {
    return this.fetchWithAuth<CommissionSummaryResponse>(
      `/services/apexrest/PO3/commissionSummary?month=${month}&year=${year}&doterraids=[${doterraIds.join(
        ","
      )}]`
    );
  }

  async slots(month: MonthNumber, year: number) {
    return this.fetchWithAuth<SlotsResponse>(
      `/services/apexrest/PO3/slot?month=${month}&year=${year}`
    );
  }

  async updateSlot(slot: Slot) {
    return this.updateSlots([slot]);
  }

  async updateSlots(slots: Slot[]) {
    if (!slots.length) {
      return;
    }

    const slotsJson = JSON.stringify(slots);

    return this.fetchWithAuth(
      `/services/apexrest/PO3/slot?slots=${slotsJson}`,
      {
        method: "PUT",
      }
    );
  }

  async deleteSlots(slots: Slot[]) {
    if (!slots.length) {
      return;
    }

    return this.updateSlots(
      slots.map((slot) => ({ ...slot, isActive: false }))
    );
  }

  async commissionSummaryHistory(
    month: MonthNumber,
    year: number,
    doterraIds: string[],
    monthsBack: number
  ) {
    return this.fetchWithAuth<CommissionSummaryResponse>(
      `/services/apexrest/PO3/commissionSummary?month=${month}&year=${year}&doterraids=[${doterraIds.join(
        ","
      )}]&monthsBack=${monthsBack}`
    );
  }

  private url(path: string) {
    return `${this.baseUrl}/${path.replace(/^\//g, "")}`;
  }

  private async fetchWithAuth<T>(path: string, config?: RequestInit) {
    try {
      return await fetchJson<T>(this.url(path), {
        ...config,
        headers: {
          ...config?.headers,
          Authorization: `Bearer ${this.accessToken}`,
        },
      });
    } catch (error) {
      if (error instanceof ResponseError && error.code === 401) {
        this.onUnauthorized();
      }

      if (error instanceof Error) {
        throw new SalesforceApiError(error.message);
      }

      throw new SalesforceApiError("Unknown error");
    }
  }
}
