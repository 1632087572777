import {
  Button,
  LoadingIndicator,
  Stack,
} from "@doterra-design-system/design-system";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../Hooks/useAuth";

export const Oauth2Callback = () => {
  const { t } = useTranslation();
  const { callback: authCallback } = useAuth();
  const navigate = useNavigate();
  const hasRun = useRef(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (hasRun.current) {
      return;
    }

    hasRun.current = true;

    const func = async () => {
      try {
        const callbackSuccess = await authCallback();

        if (callbackSuccess) {
          navigate("/");
        } else {
          throw new Error("Callback failed");
        }
      } catch (error) {
        console.error(error);
        setHasError(true);
      }
    };

    void func();
  }, [authCallback, navigate]);

  const FullScreen = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  `;

  return (
    <FullScreen>
      {hasError ? (
        <Stack align="center" spacing={"md"}>
          <div>{t("auth.callbackError")}</div>
          <div>
            <Button
              onClick={() => navigate("/login")}
              label={t("auth.callbackErrorLoginButton")}
            />
          </div>
        </Stack>
      ) : (
        <LoadingIndicator label={t("auth.awaitingCallbackResult")} />
      )}
    </FullScreen>
  );
};
