/* eslint-disable no-undefined */
import {
  TeamMemberSelectDrawer as BaseTeamMemberSelectDrawer,
  TeamMemberCardProps,
} from "@doterra-design-system/leader-tools-components/Po3";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDownline } from "../../Hooks/useDownline";
import { shortDate } from "../../Utilities/date";
import { MonthNumber } from "../../types";

export interface TeamMemberSelectDrawerProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  year: number;
  month: MonthNumber;
  parentDoterreaId: string;
  excludedDoterraIds?: string[];
  onSelected: (doterraId: string) => void;
  onInfoClicked: (doterraId: string) => void;
}

export const TeamMemberSelectDrawer: React.FC<TeamMemberSelectDrawerProps> = ({
  state,
  year,
  month,
  parentDoterreaId,
  excludedDoterraIds = [],
  onSelected,
  onInfoClicked,
}) => {
  const [isOpen] = state;
  const [filters, setFilters] = useState<string[]>([]);
  const { t } = useTranslation();

  const downline = useDownline(
    month,
    year,
    isOpen ? parentDoterreaId : undefined
  );

  const accounts = (downline.accounts ?? []).filter((account) => {
    if (excludedDoterraIds.includes(account.doTERRAId)) {
      return false;
    }

    if (
      filters.includes("100-lrp-pv") &&
      account.commissionSummary.lrpPv < 100
    ) {
      return false;
    }

    if (
      filters.includes("qualified-bonus") &&
      !account.commissionSummary.po3Bonus
    ) {
      return false;
    }

    if (
      filters.includes("qualified-schedule") &&
      account.commissionSummary.scheduledLrpPv < 100
    ) {
      return false;
    }

    if (filters.includes("template-setup") && !account.hasLRPTemplate) {
      return false;
    }

    return true;
  });

  const accountsAsTeamMembers: TeamMemberCardProps[] = accounts.map(
    (account) => ({
      firstName: account.firstName,
      lastName: account.lastName,
      lrpNeeded: 100,
      lrpProcessed: account.commissionSummary.lrpPv,
      lrpScheduled: account.commissionSummary.scheduledLrpPv,
      lrpScheduledDate: shortDate(account.commissionSummary.nextLRPRunDate),
      peopleEnrolled: account.commissionSummary.noEnrolledLastTwoMonths,
      onInfoClicked: () => onInfoClicked(account.doTERRAId),
      onClick: () => onSelected(account.doTERRAId),
    })
  );

  return (
    <BaseTeamMemberSelectDrawer
      title={t("teamMemberSelectDrawer.title")}
      state={state}
      isLoading={downline.isLoading}
      onSelected={(i) => onSelected(accounts[i].doTERRAId)}
      teamMembers={accountsAsTeamMembers}
      filterOptions={[
        {
          label: t("teamMemberSelectDrawer.filters.lrpPv100"),
          value: "100-lrp-pv",
        },
        {
          label: t("teamMemberSelectDrawer.filters.qualifiedBonus"),
          value: "qualified-bonus",
        },
        {
          label: t("teamMemberSelectDrawer.filters.qualifiedSchedule"),
          value: "qualified-schedule",
        },
        {
          label: t("teamMemberSelectDrawer.filters.templateSetup"),
          value: "template-setup",
        },
      ]}
      onFilterChange={(f) => setFilters(f)}
      filterValues={filters}
    />
  );
};
