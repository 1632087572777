import { DesignSystem } from "@doterra-design-system/design-system";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthenticatedLayout, UnauthenticatedLayout } from "./Components";
import { AuthProvider } from "./Hooks/useAuth";
import { Dashboard, Login, NotFound, Oauth2Callback } from "./Pages";
import { Po3ComponentsI18nProvider } from "./Providers/Po3ComponentsI18nProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 30, // 30 minutes
      throwOnError: true,
      retry: 2,
    },
  },
});

type AppProps = {
  children?: React.ReactNode;
};

export const App: React.FC<AppProps> = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <DesignSystem>
          <Po3ComponentsI18nProvider>
            <AuthProvider>
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route element={<UnauthenticatedLayout />}>
                  <Route path="/login" element={<Login />} />
                  <Route path="/oauth2-callback" element={<Oauth2Callback />} />
                </Route>
                <Route element={<AuthenticatedLayout />}>
                  <Route path="/" element={<Dashboard />} />
                </Route>
              </Routes>
            </AuthProvider>
          </Po3ComponentsI18nProvider>
        </DesignSystem>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
