/* eslint-disable no-undefined */
import {
  Card,
  Flex,
  IconButton,
  LoadingIndicator,
  PageHeader,
  SelectOption,
  Stack,
  Text,
  useBreakpoints,
} from "@doterra-design-system/design-system";
import {
  BonusOverviewCard,
  DeleteTrackerModal,
  LrpKeyCard,
  MonthPagination,
  TrackerTabs,
} from "@doterra-design-system/leader-tools-components/Po3";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import {
  Container,
  Layout,
  SearchOverlay,
  TeamMemberDetailsModal,
  TeamMemberSelectDrawer,
  TrackerSlots,
} from "../../Components";
import { OtherQualifiedTeamMemberSelectDrawer } from "../../Components/OtherQualifiedTeamMemberSelectDrawer";
import { useCommissionSchedule } from "../../Hooks/useCommissionSchedule";
import { useOtherQualifiedTeamMembers } from "../../Hooks/useOtherQualifiedTeamMembers";
import { useReportingPeriod } from "../../Hooks/useReportingPeriod";
import { useTracker } from "../../Hooks/useTracker";
import { useUser } from "../../Hooks/useUser";
import { formatCurrency } from "../../Utilities/currency";
import { shortDate } from "../../Utilities/date";
import { BonusLevel } from "../../types";

const BonusGrid = styled.div(
  ({ theme: { spacing, breakpoint } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: ${spacing[2]};

    ${breakpoint.up("lg")} {
      grid-template-columns: 1fr 300px;
      grid-gap: ${spacing[3]} ${spacing[6]};
    }

    ${breakpoint.up("xl")} {
      grid-template-columns: 1fr 450px;
    }
  `
);

const InfoText = styled.div`
  max-width: 770px;
  margin: 0 auto;
`;

export const Dashboard = () => {
  const user = useUser();
  const { t } = useTranslation();
  const { lgUp, lgDown, mdUp, mdDown } = useBreakpoints();

  const commissionSchedule = useCommissionSchedule();

  const bonusLevelOptions: SelectOption[] = commissionSchedule.bonusLevels.map(
    (amount, index) => ({
      label: formatCurrency(
        amount,
        user.language,
        commissionSchedule.currencyType,
        false
      ),
      value: (index + 1).toString(),
    })
  );

  const [showSearch, setShowSearch] = useState<boolean>(false);

  const reportingPeriod = useReportingPeriod();
  const pagination = {
    currentMonth: reportingPeriod.label,
    hasPreviousMonth: reportingPeriod.hasPrevious(),
    hasNextMonth: reportingPeriod.hasNext(),
    onNextMonthClick: () => {
      reportingPeriod.next();
    },
    onPreviousMonthClick: () => {
      reportingPeriod.previous();
    },
  };

  const [activeTracker, setActiveTracker] = useState<number>(1);
  const [teamMemberSelectDrawerIsOpen, setTeamMemberSelectDrawerIsOpen] =
    useState<boolean>(false);
  const [
    otherQualifiedTeamMemberSelectDrawerIsOpen,
    setOtherQualifiedTeamMemberSelectDrawerIsOpen,
  ] = useState<boolean>(false);
  const [editingSlot, setEditingSlot] = useState<null | {
    firstLevelSlotNumber: number;
    secondLevelSlotNumber: number | undefined;
    thirdLevelSlotNumber: number | undefined;
  }>(null);
  const [teamMemberDetailsModalIsOpen, setTeamMemberDetailsModalIsOpen] =
    useState<boolean>(false);
  const [teamMemberDetailsDoterreaId, setTeamMemberDetailsDoterreaId] =
    useState<string>("");
  const [deleteTrackerModalIsOpen, setDeleteTrackerModalIsOpen] =
    useState<boolean>(false);

  const tracker = useTracker(
    reportingPeriod.month,
    reportingPeriod.year,
    activeTracker
  );

  const otherQualifiedTeamMembers = useOtherQualifiedTeamMembers(
    reportingPeriod.month,
    reportingPeriod.year,
    tracker?.data.bonusLevel ?? 1,
    tracker?.data.usedAccountIds ?? []
  );

  const [downlineDoterraId, setDownlineDoterraId] = useState<string>(
    user.doterraId
  );

  useEffect(() => {
    if (!editingSlot) {
      return;
    }

    const parent = tracker?.getParentSlotAtPosition(
      editingSlot.firstLevelSlotNumber,
      editingSlot.secondLevelSlotNumber,
      editingSlot.thirdLevelSlotNumber
    );

    setDownlineDoterraId(parent ? parent.doterraId : user.doterraId);
  }, [editingSlot, user, tracker]);

  const openTeamMemberDetailsModalForSlotPosition = (
    firstLevelSlotNumber: number,
    secondLevelSlotNumber: number | undefined,
    thirdLevelSlotNumber: number | undefined
  ) => {
    const slot = tracker?.getSlotAtPosition(
      firstLevelSlotNumber,
      secondLevelSlotNumber,
      thirdLevelSlotNumber
    );

    if (!slot) {
      return;
    }

    setTeamMemberDetailsDoterreaId(slot.doterraId);
    setTeamMemberDetailsModalIsOpen(true);
  };

  return (
    <Layout headerIsOnTopOfOverlay={showSearch} isSaving={tracker?.isUpdating}>
      <SearchOverlay
        state={[showSearch, setShowSearch]}
        month={reportingPeriod.month}
        year={reportingPeriod.year}
        onSearchResultInfoClicked={(doterraId) => {
          setTeamMemberDetailsDoterreaId(doterraId);
          setTeamMemberDetailsModalIsOpen(true);
        }}
      />
      <PageHeader>
        <Container>
          <Stack>
            <Flex align="baseline" justify="space-between">
              <PageHeader.Title>
                <Text weight="extraBold">{user.firstName}</Text>{" "}
                <Text weight="semiBold">{user.lastName}</Text>
              </PageHeader.Title>
              <Flex spacing="md" align="center">
                <IconButton
                  icon="search"
                  onClick={() => setShowSearch(true)}
                  accessibilityLabel="Search"
                />
                {mdUp && (
                  <MonthPagination
                    currentMonth={pagination.currentMonth}
                    hasPreviousMonth={pagination.hasPreviousMonth}
                    hasNextMonth={pagination.hasNextMonth}
                    onNextClick={pagination.onNextMonthClick}
                    onPreviousClick={pagination.onPreviousMonthClick}
                  />
                )}
              </Flex>
            </Flex>
            {mdDown && (
              <MonthPagination
                currentMonth={pagination.currentMonth}
                hasPreviousMonth={pagination.hasPreviousMonth}
                hasNextMonth={pagination.hasNextMonth}
                onNextClick={pagination.onNextMonthClick}
                onPreviousClick={pagination.onPreviousMonthClick}
              />
            )}
          </Stack>
        </Container>
      </PageHeader>
      <Container>
        {!tracker?.isLoading && !tracker?.isLoadingAnotherMonth && tracker ? (
          <>
            <Stack spacing="lg">
              <BonusGrid>
                {(tracker.numberOfTrackers > 1 || tracker.canAddTracker) && (
                  <>
                    <TrackerTabs
                      numberOfTrackers={tracker.numberOfTrackers}
                      canAddTracker={tracker.canAddTracker}
                      canDeleteTracker={true}
                      activeTracker={activeTracker}
                      onTrackerChange={(tr) => setActiveTracker(tr)}
                      onAddTrackerClick={() => {
                        void tracker.addTracker();
                        setActiveTracker(activeTracker + 1);
                      }}
                      onDeleteTrackerClick={() =>
                        setDeleteTrackerModalIsOpen(true)
                      }
                    />
                    <div />
                  </>
                )}
                <BonusOverviewCard
                  lrpPv={tracker.data.personalContribution.lrpPv}
                  lrpPvTarget={tracker.data.personalContribution.lrpPvTarget}
                  lrpPvScheduled={
                    tracker.data.personalContribution.lrpPvScheduled ??
                    undefined
                  }
                  lrpPvScheduledDate={
                    shortDate(
                      tracker.data.personalContribution.lrpPvScheduledDate
                    ) ?? undefined
                  }
                  tv={tracker.data.personalContribution.tv}
                  tvTarget={tracker.data.personalContribution.tvTarget}
                  bonusLevelOptions={bonusLevelOptions}
                  onBonusLevelChange={(newBonusLevel) => {
                    void tracker.changeBonusLevel(
                      parseInt(newBonusLevel, 10) as BonusLevel
                    );
                  }}
                  selectedBonusLevel={tracker.data.bonusLevel.toString()}
                  possibleTeamMembersCount={
                    otherQualifiedTeamMembers.accounts.length
                  }
                  onAddTeamMembersClick={() =>
                    setOtherQualifiedTeamMemberSelectDrawerIsOpen(true)
                  }
                  onLrpPvDonutChartClick={() => {
                    setTeamMemberDetailsDoterreaId(user.doterraId);
                    setTeamMemberDetailsModalIsOpen(true);
                  }}
                  onTvDonutChartClick={() => {
                    setTeamMemberDetailsDoterreaId(user.doterraId);
                    setTeamMemberDetailsModalIsOpen(true);
                  }}
                  memberImage={user.imageUrl}
                />
                {lgUp && <LrpKeyCard />}
              </BonusGrid>
              <TrackerSlots
                slots={tracker.data.slots}
                backupSlots={tracker.data.backupSlots}
                onAddTeamMember={(
                  firstLevelSlotNumber,
                  secondLevelSlotNumber,
                  thirdLevelSlotNumber
                ) => {
                  setEditingSlot({
                    firstLevelSlotNumber,
                    secondLevelSlotNumber,
                    thirdLevelSlotNumber,
                  });
                  setTeamMemberSelectDrawerIsOpen(true);
                }}
                onClearAllForSlot={(slotIndex) => {
                  void tracker.clearAllForSlot(slotIndex);
                }}
                onLrpDonutChartClick={openTeamMemberDetailsModalForSlotPosition}
                onTvDonutChartClick={openTeamMemberDetailsModalForSlotPosition}
              />
              {lgDown && <LrpKeyCard />}
              <InfoText>
                <Text element="p" align="center" size="textXs">
                  {t("dashboard.disclaimer")}
                </Text>
              </InfoText>
            </Stack>
          </>
        ) : (
          <Flex justify="center">
            <Card>
              <Card.Body>
                <LoadingIndicator label={t("dashboard.loading")} />
              </Card.Body>
            </Card>
          </Flex>
        )}
      </Container>
      <TeamMemberSelectDrawer
        state={[teamMemberSelectDrawerIsOpen, setTeamMemberSelectDrawerIsOpen]}
        year={reportingPeriod.year}
        month={reportingPeriod.month}
        excludedDoterraIds={tracker?.data.usedAccountIds ?? []}
        parentDoterreaId={downlineDoterraId}
        onSelected={(doterraId) => {
          if (!editingSlot) {
            return;
          }

          void tracker?.setSlot(
            doterraId,
            editingSlot.firstLevelSlotNumber,
            editingSlot.secondLevelSlotNumber,
            editingSlot.thirdLevelSlotNumber
          );
          setTeamMemberSelectDrawerIsOpen(false);
          setEditingSlot(null);
        }}
        onInfoClicked={(doterraId) => {
          setTeamMemberDetailsDoterreaId(doterraId);
          setTeamMemberDetailsModalIsOpen(true);
        }}
      />
      <OtherQualifiedTeamMemberSelectDrawer
        state={[
          otherQualifiedTeamMemberSelectDrawerIsOpen,
          setOtherQualifiedTeamMemberSelectDrawerIsOpen,
        ]}
        year={reportingPeriod.year}
        month={reportingPeriod.month}
        bonusLevel={tracker?.data.bonusLevel ?? 1}
        excludedDoterraIds={tracker?.data.usedAccountIds ?? []}
        onSelected={(doterraId) => {
          if (!tracker) {
            return;
          }

          const firstAvailableSlot = tracker.data.slots.findIndex(
            (slot) => slot.member === undefined
          );

          void tracker.setSlot(
            doterraId,
            firstAvailableSlot,
            undefined,
            undefined
          );

          setOtherQualifiedTeamMemberSelectDrawerIsOpen(false);
          setEditingSlot(null);
        }}
        onInfoClicked={(doterraId) => {
          setTeamMemberDetailsDoterreaId(doterraId);
          setTeamMemberDetailsModalIsOpen(true);
        }}
      />
      <TeamMemberDetailsModal
        doterraId={teamMemberDetailsDoterreaId}
        state={[teamMemberDetailsModalIsOpen, setTeamMemberDetailsModalIsOpen]}
      />
      <DeleteTrackerModal
        state={[deleteTrackerModalIsOpen, setDeleteTrackerModalIsOpen]}
        trackerLabel={
          t("po3Components:trackerTabs.tracker") +
          " " +
          (tracker?.numberOfTrackers ?? 1).toString()
        }
        onConfirmDelete={async () => {
          const rightMostTabNumber = tracker?.numberOfTrackers ?? 1;
          await tracker?.deleteTab(rightMostTabNumber);
          setDeleteTrackerModalIsOpen(false);
          setActiveTracker(Math.max(activeTracker - 1, 1));
        }}
        onCancel={() => setDeleteTrackerModalIsOpen(false)}
      />
    </Layout>
  );
};
