import {
  Avatar,
  Header,
  LinkStack,
  Stack,
  Text,
} from "@doterra-design-system/design-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useAuth } from "../../Hooks/useAuth";
import { useUser } from "../../Hooks/useUser";

const StyledMenu = styled(Header.Menu)(
  () => css`
    padding: 0;
  `
);

const Head = styled.div(
  ({ theme: { color, spacing } }) => css`
    padding: ${spacing[4]};
    background: ${color.background.extraLight};
    border-bottom: 1px solid ${color.border.light};
  `
);

const Body = styled.div(
  ({ theme: { spacing } }) => css`
    padding: ${spacing[4]};
  `
);

export const UserMenu: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUser();
  const auth = useAuth();

  return (
    <StyledMenu trigger={<Avatar image={user.imageUrl} />}>
      <Head>
        <Stack spacing={1}>
          <Text>{t("header.userMenu.welcome")}</Text>
          <Text weight="bold">
            {user.firstName} {user.lastName}
          </Text>
        </Stack>
      </Head>
      <Body>
        <LinkStack>
          <LinkStack.Link
            icon="logOut"
            iconPosition="left"
            onClick={() => {
              void (async () => {
                await auth.logout();
                navigate("/logout");
              })();
            }}
          >
            {t("header.userMenu.signOut")}
          </LinkStack.Link>
        </LinkStack>
      </Body>
    </StyledMenu>
  );
};
