import {
  TeamMemberSelectDrawer as BaseOtherQualifiedTeamMemberSelectDrawer,
  TeamMemberCardProps,
} from "@doterra-design-system/leader-tools-components/Po3";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOtherQualifiedTeamMembers } from "../../Hooks/useOtherQualifiedTeamMembers";
import { shortDate } from "../../Utilities/date";
import { BonusLevel, MonthNumber } from "../../types";

export interface OtherQualifiedTeamMemberSelectDrawerProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  year: number;
  month: MonthNumber;
  bonusLevel: BonusLevel;
  excludedDoterraIds?: string[];
  onSelected: (doterraId: string) => void;
  onInfoClicked: (doterraId: string) => void;
}

export const OtherQualifiedTeamMemberSelectDrawer: React.FC<
  OtherQualifiedTeamMemberSelectDrawerProps
> = ({
  state,
  year,
  month,
  excludedDoterraIds = [],
  onSelected,
  onInfoClicked,
  bonusLevel,
}) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<string[]>([]);

  const otherQualifiedTeamMembers = useOtherQualifiedTeamMembers(
    month,
    year,
    bonusLevel,
    excludedDoterraIds
  );

  const accounts = (otherQualifiedTeamMembers.accounts ?? []).filter(
    (account) => {
      if (excludedDoterraIds.includes(account.doTERRAId)) {
        return false;
      }

      if (
        filters.includes("100-lrp-pv") &&
        account.commissionSummary.lrpPv < 100
      ) {
        return false;
      }

      if (
        filters.includes("qualified-bonus") &&
        !account.commissionSummary.po3Bonus
      ) {
        return false;
      }

      if (
        filters.includes("qualified-schedule") &&
        account.commissionSummary.scheduledLrpPv < 100
      ) {
        return false;
      }

      if (filters.includes("template-setup") && !account.hasLRPTemplate) {
        return false;
      }

      return true;
    }
  );

  const accountsAsTeamMembers: TeamMemberCardProps[] = accounts.map(
    (account) => ({
      firstName: account.firstName,
      lastName: account.lastName,
      lrpNeeded: 100,
      lrpProcessed: account.commissionSummary.lrpPv,
      lrpScheduled: account.commissionSummary.scheduledLrpPv,
      lrpScheduledDate: shortDate(account.commissionSummary.nextLRPRunDate),
      peopleEnrolled: account.commissionSummary.noEnrolledLastTwoMonths,
      onInfoClicked: () => onInfoClicked(account.doTERRAId),
      onClick: () => onSelected(account.doTERRAId),
    })
  );

  return (
    <BaseOtherQualifiedTeamMemberSelectDrawer
      title={t("otherQualifiedTeamMemberSelectDrawer.title")}
      state={state}
      isLoading={otherQualifiedTeamMembers.isLoading}
      onSelected={(i) => onSelected(accounts[i].doTERRAId)}
      teamMembers={accountsAsTeamMembers}
      filterOptions={[
        {
          label: t("otherQualifiedTeamMemberSelectDrawer.filters.lrpPv100"),
          value: "100-lrp-pv",
        },
        {
          label: t(
            "otherQualifiedTeamMemberSelectDrawer.filters.qualifiedBonus"
          ),
          value: "qualified-bonus",
        },
        {
          label: t(
            "otherQualifiedTeamMemberSelectDrawer.filters.qualifiedSchedule"
          ),
          value: "qualified-schedule",
        },
        {
          label: t(
            "otherQualifiedTeamMemberSelectDrawer.filters.templateSetup"
          ),
          value: "template-setup",
        },
      ]}
      onFilterChange={(f) => setFilters(f)}
      filterValues={filters}
    />
  );
};
