import { Container as BaseContainer } from "@doterra-design-system/design-system";
import React from "react";
import styled, { css } from "styled-components";

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
}

const StyledContainer = styled(BaseContainer)(
  ({ theme: { breakpoint } }) => css`
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 12px;

    ${breakpoint.up("lg")} {
      padding: 0 60px;
    }
  `
);

export const Container: React.FC<ContainerProps> = ({
  children,
  className,
}) => {
  return <StyledContainer className={className}>{children}</StyledContainer>;
};
