import { useBreakpoints } from "@doterra-design-system/design-system";
import {
  SearchOverlay as BaseSearchOverlay,
  TeamMemberCardProps,
} from "@doterra-design-system/leader-tools-components/Po3";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSalesforceApi } from "../../Hooks/useSalesforceApi";
import { MonthNumber } from "../../types";
import { queryKeys } from "../../Utilities";
import { transformCommissionSummaries } from "../../Utilities/comission";
import { shortDate } from "../../Utilities/date";

interface SearchOverlayProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  month: MonthNumber;
  year: number;
  onSearchResultInfoClicked: (doterraId: string) => void;
}

export const SearchOverlay: React.FC<SearchOverlayProps> = ({
  state: [showSearch, setShowSearch],
  month,
  year,
  onSearchResultInfoClicked,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [filters, setFilters] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<TeamMemberCardProps[]>();
  const { t } = useTranslation();
  const { lgUp } = useBreakpoints();
  const api = useSalesforceApi();

  const {
    data: accountResults,
    isFetching: accountResultsIsFetching,
    refetch: fetchAccounts,
  } = useQuery({
    queryKey: queryKeys.accountsSearchResults(searchValue),
    queryFn: () => api.accountSearch(searchValue),
    enabled: false,
    placeholderData: keepPreviousData,
  });

  const doterraIds = accountResults?.map((account) => account.doTERRAId) ?? [];

  const {
    data: commissionSummaries,
    isFetching: commissionSummariesIsFetching,
  } = useQuery({
    queryKey: queryKeys.commissionSummaries(month, year, doterraIds),
    queryFn: () => api.commissionSummary(month, year, doterraIds),
    enabled: Boolean(doterraIds.length),
    placeholderData: keepPreviousData,
    select: transformCommissionSummaries,
  });

  useEffect(() => {
    setSearchResults(
      accountResults
        ?.filter((account) => {
          if (filters.length === 0) {
            return true;
          }

          let shouldShow = false;

          if (filters.includes("level-1")) {
            shouldShow = shouldShow || account.downlineLevel === "2";
          }

          if (filters.includes("level-2")) {
            shouldShow = shouldShow || account.downlineLevel === "3";
          }

          if (filters.includes("level-3")) {
            shouldShow = shouldShow || !account.downlineLevel;
          }

          return shouldShow;
        })
        .map((account) => {
          const commissionSummary = commissionSummaries?.find(
            (summary) => summary.accountId === account.doTERRAId
          );

          const teamMember: TeamMemberCardProps = {
            firstName: account.firstName,
            lastName: account.lastName,
            lrpNeeded: 100,
            lrpProcessed: commissionSummary?.lrpPv ?? 0,
            lrpScheduled: commissionSummary?.scheduledLrpPv ?? 0,
            lrpScheduledDate: shortDate(commissionSummary?.nextLRPRunDate),
            peopleEnrolled: commissionSummary?.noEnrolledLastTwoMonths ?? 0,
            onInfoClicked: () => {
              onSearchResultInfoClicked(account.doTERRAId);
            },
          };

          return teamMember;
        })
    );
  }, [accountResults, commissionSummaries, filters, onSearchResultInfoClicked]);

  return (
    <BaseSearchOverlay
      offsetTop={lgUp ? 100 : 70}
      searchValue={searchValue}
      filterValues={filters}
      filterOptions={[
        {
          label: t("searchOverlay.filters.level1"),
          value: "level-1",
        },
        {
          label: t("searchOverlay.filters.level2"),
          value: "level-2",
        },
        {
          label: t("searchOverlay.filters.level3"),
          value: "level-3",
        },
      ]}
      onSearchValueChange={(newValue: string) => {
        setSearchValue(newValue);
      }}
      onFilterChange={(newValue: string[]) => setFilters(newValue)}
      isVisible={showSearch}
      onClose={() => {
        setShowSearch(false);
      }}
      onSearch={() => {
        void fetchAccounts();
      }}
      results={searchResults}
      isLoading={accountResultsIsFetching || commissionSummariesIsFetching}
    />
  );
};
