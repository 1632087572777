/* eslint-disable no-undefined */
import {
  Stack,
  Text,
  useBreakpoints,
} from "@doterra-design-system/design-system";
import {
  HorizontalScroller,
  PlaceholderCard,
  TeamMemberEditCard,
  TeamMemberMultiLevelEditCard,
} from "@doterra-design-system/leader-tools-components/Po3";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { shortDate } from "../../Utilities/date";
import {
  isOneLevelSlot,
  isThreeLevelSlot,
  isTwoLevelSlot,
} from "../../Utilities/tracker";
import {
  AnyLevelSlot,
  TrackerBackupSlots,
  TrackerSlots as TrackerSlotsType,
} from "../../types";

interface TrackerSlotsProps {
  slots: TrackerSlotsType;
  backupSlots?: TrackerBackupSlots;
  onAddTeamMember: (
    firstLevelSlotNumber: number,
    secondLevelSlotNumber?: number,
    thirdLevelSlotNumber?: number
  ) => void;
  onLrpDonutChartClick?: (
    firstLevelSlotNumber: number,
    secondLevelSlotNumber?: number,
    thirdLevelSlotNumber?: number
  ) => void;
  onTvDonutChartClick?: (
    firstLevelSlotNumber: number,
    secondLevelSlotNumber?: number,
    thirdLevelSlotNumber?: number
  ) => void;
  onClearAllForSlot: (slotIndex: number) => void;
}

const Wrapper = styled.div(
  ({ theme: { breakpoint } }) => css`
    margin: 0;

    ${breakpoint.up("lg")} {
      margin: 0 -52px;
    }
  `
);

interface MainSlotStackProps {
  isLast: boolean;
}

const MainSlotStack = styled(Stack)<MainSlotStackProps>(
  ({ isLast }) => css`
    margin-right: ${isLast ? "32px" : 0};
  `
);

const StyledPlaceholderCard = styled(PlaceholderCard)`
  width: 150px;
  height: 100%;
  min-height: 190px;
`;

const StyledTeamMemberEditCard = styled(TeamMemberEditCard)`
  width: 150px;
  height: 100%;
`;

const StyledTeamMemberMultiLevelEditCard = styled(TeamMemberMultiLevelEditCard)`
  width: 270px;
  height: 100%;
`;

export const TrackerSlots: React.FC<TrackerSlotsProps> = ({
  slots,
  backupSlots,
  onAddTeamMember,
  onClearAllForSlot,
  onLrpDonutChartClick,
  onTvDonutChartClick,
}) => {
  const { lgUp } = useBreakpoints();
  const { t } = useTranslation();

  const renderSlot = (slot: AnyLevelSlot, index: number) => {
    if (isOneLevelSlot(slot)) {
      if (!slot.member) {
        return (
          <StyledPlaceholderCard
            label={t("trackerSlots.addTeamMember")}
            onClick={() => onAddTeamMember(index, undefined, undefined)}
          />
        );
      }

      return (
        <StyledTeamMemberEditCard
          firstName={slot.member.firstName}
          lastName={slot.member.lastName}
          lrpNeeded={slot.member.lrpNeeded}
          lrpProcessed={slot.member.lrpProcessed}
          lrpScheduled={slot.member.lrpScheduled}
          lrpScheduledDate={shortDate(slot.member.lrpScheduledDate)}
          onEdit={() => onAddTeamMember(index, undefined, undefined)}
          onLrpDonutChartClick={() => {
            onLrpDonutChartClick &&
              onLrpDonutChartClick(index, undefined, undefined);
          }}
        />
      );
    }

    if (isTwoLevelSlot(slot) || isThreeLevelSlot(slot)) {
      return (
        <StyledTeamMemberMultiLevelEditCard
          data={slot}
          onChangeMember={(level, memberIndex) => {
            onAddTeamMember(index, level, memberIndex);
          }}
          onClearAll={() => {
            onClearAllForSlot(index);
          }}
          onLrpDonutChartClick={(secondLevelSlotIndex, thirdLevelSlotIndex) => {
            onLrpDonutChartClick &&
              onLrpDonutChartClick(
                index,
                secondLevelSlotIndex,
                thirdLevelSlotIndex
              );
          }}
          onTvDonutChartClick={(secondLevelSlotIndex, thirdLevelSlotIndex) => {
            onTvDonutChartClick &&
              onTvDonutChartClick(
                index,
                secondLevelSlotIndex,
                thirdLevelSlotIndex
              );
          }}
        />
      );
    }
  };

  return (
    <Wrapper>
      <HorizontalScroller showFade={lgUp} showArrows={lgUp}>
        {slots.map((slot, index) => {
          return (
            <MainSlotStack key={index} isLast={index === 2}>
              <Text size="textMd" weight="bold">
                {index === 0 ? t("trackerSlots.mainLegTitle") : "\u00A0"}
              </Text>
              {renderSlot(slot, index)}
            </MainSlotStack>
          );
        })}
        {backupSlots?.map((slot, index) => {
          return (
            <Stack key={index}>
              <Text size="textMd" weight="bold">
                {t("trackerSlots.backupLegTitle")}
              </Text>
              {renderSlot(slot, index + 3)}
            </Stack>
          );
        })}
      </HorizontalScroller>
    </Wrapper>
  );
};
