import { BonusLevel, MonthNumber } from "../types";
import { useDownline } from "./useDownline";
import { useUser } from "./useUser";

export function useOtherQualifiedTeamMembers(
  month: MonthNumber,
  year: number,
  bonusLevel: BonusLevel,
  excludedDoterraIds: string[]
) {
  const user = useUser();
  const { accounts: downlineAccounts, isLoading: downlineIsLoading } =
    useDownline(month, year, user.doterraId);

  const accounts =
    downlineAccounts?.filter((account) => {
      if (excludedDoterraIds.includes(account.doTERRAId)) {
        return false;
      }

      if (account.commissionSummary.lrpPv < 100) {
        return false;
      }

      const dl = parseInt(account.downlineLevel, 10);
      const bl = bonusLevel;

      return dl <= bl && dl + account.commissionSummary.po3Rank >= bl;
    }) ?? [];

  return {
    accounts,
    isLoading: downlineIsLoading,
  };
}
