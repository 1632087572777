import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../Utilities";
import { transformCommissionSummary } from "../Utilities/comission";
import { MonthNumber } from "../types";
import { useSalesforceApi } from "./useSalesforceApi";

export function useDownline(
  month: MonthNumber,
  year: number,
  doterraId?: string
) {
  const api = useSalesforceApi();

  const { data: accounts, isLoading: downlineIsLoading } = useQuery({
    queryKey: queryKeys.downline(month, year, doterraId ?? ""),
    queryFn: () => {
      if (!doterraId) {
        throw new Error("doterraId is required");
      }

      return api.downline(doterraId, month, year);
    },
    enabled: Boolean(doterraId),
    select: (data) => {
      return data.map((account) => ({
        ...account,
        commissionSummary: transformCommissionSummary(
          account.commissionSummary
        ),
      }));
    },
  });

  return {
    accounts,
    isLoading: downlineIsLoading,
  };
}
