/* eslint-disable no-undefined */
export class Lineage {
  private constructor(
    readonly ownerDoterraId: string,
    readonly year: number,
    readonly month: number,
    readonly tabNumber: number,
    readonly firstLevelSlotNumber?: number,
    readonly secondLevelSlotNumber?: number,
    readonly thirdLevelSlotNumber?: number
  ) {}

  public static decode(lineage: string): Lineage {
    const parts = lineage.split("|");

    const [ownerDoterraId] = parts;
    const year = parseInt(this.getElementAtIndexOrThrow(parts, 1), 10);
    const month = parseInt(this.getElementAtIndexOrThrow(parts, 2), 10);
    const tabNumber = parseInt(this.getElementAtIndexOrThrow(parts, 3), 10);
    const firstLevelSlotNumber = this.getElementAtIndexOrUndefined(parts, 4)
      ? parseInt(this.getElementAtIndexOrThrow(parts, 4), 10)
      : undefined;
    const secondLevelSlotNumber = this.getElementAtIndexOrUndefined(parts, 5)
      ? parseInt(this.getElementAtIndexOrThrow(parts, 5), 10)
      : undefined;
    const thirdLevelSlotNumber = this.getElementAtIndexOrUndefined(parts, 6)
      ? parseInt(this.getElementAtIndexOrThrow(parts, 6), 10)
      : undefined;

    return new Lineage(
      ownerDoterraId,
      year,
      month,
      tabNumber,
      firstLevelSlotNumber,
      secondLevelSlotNumber,
      thirdLevelSlotNumber
    );
  }

  public static encode(lineage: Lineage): string {
    let string = `${lineage.ownerDoterraId}|${lineage.year}|${lineage.month}|${lineage.tabNumber}`;

    if (lineage.firstLevelSlotNumber !== undefined) {
      string += `|${lineage.firstLevelSlotNumber}`;
    }

    if (lineage.secondLevelSlotNumber !== undefined) {
      string += `|${lineage.secondLevelSlotNumber}`;
    }

    if (lineage.thirdLevelSlotNumber !== undefined) {
      string += `|${lineage.thirdLevelSlotNumber}`;
    }

    return string;
  }

  private static getElementAtIndexOrThrow(
    parts: string[],
    index: number
  ): string {
    const part = parts[index];
    if (!part) {
      throw new Error(`Malformed Lineage - part ${index} not found`);
    }

    return part;
  }

  private static getElementAtIndexOrUndefined(
    parts: string[],
    index: number
  ): string | undefined {
    return parts[index];
  }
}
