export class ResponseError extends Error {
  public code: number;

  public constructor(message: string, code: number) {
    super(message);
    this.name = "ResponseError";
    this.code = code;
  }
}

export async function fetchJson<TResponse>(
  url: string,
  config?: RequestInit,
  timeout = 30000
): Promise<TResponse> {
  const fetchPromise = fetch(url, config);
  const timeoutPromise = new Promise<TResponse>((_, reject) => {
    setTimeout(() => {
      reject(new Error("Request timed out"));
    }, timeout);
  });

  return Promise.race([fetchPromise, timeoutPromise]).then(async (response) => {
    if (response instanceof Response) {
      if (!response.ok) {
        throw new ResponseError(response.statusText, response.status);
      }
      try {
        return (await response.json()) as TResponse;
      } catch (error) {
        console.warn("Failed to parse JSON response", error);
        return {} as TResponse;
      }
    } else {
      // Handle the timeout case here
      throw new Error("Request failed or timed out");
    }
  });
}
