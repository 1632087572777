import { LoadingIndicator } from "@doterra-design-system/design-system";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAuth } from "../../Hooks/useAuth";

const FullScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Login = () => {
  const { loginRedirect } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    void loginRedirect();
  }, [loginRedirect]);

  return (
    <FullScreen>
      <LoadingIndicator label={t("auth.redirectToLoginProvider")} />
    </FullScreen>
  );
};
