import { useQuery } from "@tanstack/react-query";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import i18n from "../i18n";
import { queryKeys } from "../Utilities";
import { useSalesforceApi } from "./useSalesforceApi";

export interface UserContextType {
  doterraId: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  market: string;
  marketIso: string;
  language: string;
  maxRank: number;
  email: string;
  type: string;
}

const UserContext = createContext<UserContextType | null>(null);

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within an UserProvider");
  }

  return context;
};

interface UserProviderProps {
  children: ReactNode;
  onLoadingComplete: (user: UserContextType) => void;
}

export const UserProvider = ({
  children,
  onLoadingComplete,
}: UserProviderProps) => {
  const api = useSalesforceApi();

  const { data, isLoading } = useQuery({
    queryKey: queryKeys.currentUser,
    queryFn: () => api.user(),
  });

  const user = useMemo<UserContextType>(
    () => ({
      doterraId: data?.doterraId ?? "",
      firstName: data?.details.firstName ?? "",
      lastName: data?.details.lastName ?? "",
      imageUrl: data?.details.imageUrl ?? "",
      market: data?.marketLabel ?? "",
      marketIso: data?.marketIsoCode ?? "",
      language: data?.language ?? "",
      maxRank: data?.details.currentMaxRank ?? 0,
      email: data?.details.email ?? "",
      type: data?.details.type ?? "",
    }),
    [data]
  );

  useEffect(() => {
    if (!isLoading) {
      onLoadingComplete(user);
    }
  }, [isLoading, onLoadingComplete, user]);

  useEffect(() => {
    if (data?.language) {
      void i18n.changeLanguage(data?.language);
    }
  }, [data]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
