import {
  Header as DsHeader,
  Flex,
  Link,
  LinkStack,
  LoadingIndicator,
  Logo,
  LogoMark,
  Text,
  useBreakpoints,
} from "@doterra-design-system/design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container } from "../Container";
import { UserMenu } from "../UserMenu";

interface HeaderProps {
  className?: string;
  isSaving?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  className,
  isSaving = false,
}) => {
  const { t } = useTranslation();
  const { mdUp } = useBreakpoints();
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const mobileHeader = (
    <DsHeader className={className}>
      <Container>
        <Flex justify="space-between">
          <DsHeader.Column>
            <DsHeader.Menu
              isOpen={menuIsOpen}
              onOpenChange={setMenuIsOpen}
              trigger={
                <DsHeader.IconLink icon={menuIsOpen ? "close" : "menu"} />
              }
              fullWidth
            >
              <LinkStack>
                <LinkStack.Link
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Dashboard
                </LinkStack.Link>
              </LinkStack>
            </DsHeader.Menu>
          </DsHeader.Column>
          <DsHeader.Column>
            <Link
              onClick={() => {
                navigate("/");
              }}
            >
              <LogoMark size={{ width: 40 }} />
            </Link>
          </DsHeader.Column>
          <DsHeader.Column>
            {isSaving && (
              <Flex align="center">
                <LoadingIndicator size="sm" />
                <Text color="text.secondary" size="textSm">
                  {t("header.saving")}
                </Text>
              </Flex>
            )}
            <UserMenu />
          </DsHeader.Column>
        </Flex>
      </Container>
    </DsHeader>
  );

  const desktopHeader = (
    <DsHeader className={className}>
      <Container>
        <Flex justify="space-between">
          <Flex spacing={8}>
            <DsHeader.Column>
              <Link
                onClick={() => {
                  navigate("/");
                }}
              >
                <Logo size={{ width: 115 }} />
              </Link>
            </DsHeader.Column>
            <DsHeader.Column>
              <DsHeader.Link
                active
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("header.navigation.dashboard")}
              </DsHeader.Link>
            </DsHeader.Column>
          </Flex>
          <DsHeader.Column>
            {isSaving && (
              <Flex align="center">
                <LoadingIndicator size="sm" />
                <Text color="text.secondary" size="textSm">
                  {t("header.saving")}
                </Text>
              </Flex>
            )}
            <UserMenu />
          </DsHeader.Column>
        </Flex>
      </Container>
    </DsHeader>
  );

  return mdUp ? desktopHeader : mobileHeader;
};
