import { useEffectAfterInitialRender } from "@doterra-design-system/design-system";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MonthNumber } from "../types";

const MONTHS_USER_CAN_GO_BACK = 6;
const NOW = new Date();
const THIS_MONTH = new Date(NOW.getFullYear(), NOW.getMonth(), 1);

export function useReportingPeriod() {
  const [searchParams, setSearchParams] = useSearchParams();

  const getReportingDateFromQueryParams = () => {
    const year = searchParams.get("year");
    const month = searchParams.get("month");

    if (year && month) {
      const date = new Date(parseInt(year, 10), parseInt(month, 10) - 1, 1);

      if (date > THIS_MONTH) {
        return null;
      }

      if (
        date <
        new Date(
          THIS_MONTH.getFullYear(),
          THIS_MONTH.getMonth() - MONTHS_USER_CAN_GO_BACK,
          1
        )
      ) {
        return null;
      }

      return date;
    }
  };

  const [reportingDate, setReportingDate] = useState(() => {
    return getReportingDateFromQueryParams() ?? new Date(THIS_MONTH);
  });

  const label = reportingDate.toLocaleDateString("default", {
    month: "long",
    year: "numeric",
  });

  useEffectAfterInitialRender(() => {
    setSearchParams({
      year: reportingDate.getFullYear().toString(),
      month: (reportingDate.getMonth() + 1).toString(),
    });
  }, [reportingDate]);

  const hasNext = () => {
    return THIS_MONTH > reportingDate;
  };

  const hasPrevious = () => {
    return (
      reportingDate >
      new Date(
        THIS_MONTH.getFullYear(),
        THIS_MONTH.getMonth() - MONTHS_USER_CAN_GO_BACK,
        1
      )
    );
  };

  const next = () => {
    setReportingDate(
      new Date(reportingDate.setMonth(reportingDate.getMonth() + 1))
    );
  };

  const previous = () => {
    setReportingDate(
      new Date(reportingDate.setMonth(reportingDate.getMonth() - 1))
    );
  };

  return {
    label,
    hasNext,
    hasPrevious,
    next,
    previous,
    month: (reportingDate.getMonth() + 1) as MonthNumber,
    year: reportingDate.getFullYear(),
  };
}
